<template>
  <div class="my-collect">
    <div class="m-header">
      <div class="tab">
        <van-tabs v-model:active="active" @change="handleChange">
          <van-tab title="会员商品" :key="0"></van-tab>
          <van-tab title="达人商品" :key="1"></van-tab>
        </van-tabs>
      </div>
      <div class="h-box flex align-center">
        <p
          :class="['one-t', { red: type == 1 }]"
          @click="baoFun(1)"
        >
          {{ baoStatusType ? '宝贝状态' : (type == 1 && statusText ? statusText : '宝贝状态') }}
        </p>
        <img
          v-if="type == 1"
          src="@/assets/img/user/myCollect/red.png"
          alt=""
          class="one-img"
          @click="baoFun(1)"
        />
        <img
          v-else
          src="@/assets/img/user/myCollect/xia.png"
          alt=""
          class="one-img"
          @click="baoFun(1)"
        />
        <p
          :class="['two-t', { red: type == 2 }]"
          @click="baoFun(2)"
        >
          {{ baoStatusType ? '收藏时间' : (type == 2 && timeText ? timeText : '收藏时间') }}
        </p>
        <img
          v-if="type == 2"
          src="@/assets/img/user/myCollect/red.png"
          alt=""
          class="two-img"
          @click="baoFun(2)"
        />
        <img
          v-else
          src="@/assets/img/user/myCollect/xia.png"
          alt=""
          class="two-img"
          @click="baoFun(2)"
        />
        <p
          :class="['three-t', { red: type == 3 }]"
          @click="baoFun(3)"
        >
          {{ baoStatusType ? '宝贝分类' : (type == 3 && categoryText ? categoryText : '宝贝分类') }}
        </p>
        <img
          v-if="type == 3"
          src="@/assets/img/user/myCollect/red.png"
          alt=""
          class="three-img"
          @click="baoFun(3)"
        />
        <img
          v-else
          src="@/assets/img/user/myCollect/xia.png"
          alt=""
          class="three-img"
          @click="baoFun(3)"
        />
        <div v-if="list.length > 0 && !baoStatusType" class="text flex flex-right">
          <p @click="handleFun">{{ !status ? "管理" : "完成" }}</p>
        </div>
      </div>
    </div>
    <div :class="['m-main', {'m-select': status}, {'no-list': list.length == 0}]">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="['m-li flex', { 'm-li-select': status }]"
          >
            <van-checkbox v-model="item.checked" @click="partChange">
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
              <div class="li-right flex" @click="toDetail(item)">
                <img :src="item.picture.indexOf(',') > -1 ? item.picture.split(',')[0] : item.picture" alt="" class="pic" />
                <div class="m-right">
                  <p class="title">{{ item.name }}</p>
                  <p class="desc">品牌：{{ item.brandName }}</p>
                  <div v-if="item.coupon || Number(item.score || 0) > 0" class="tip flex">
                    <p v-if="item.coupon">{{ item.coupon }}</p>
                    <p v-if="Number(item.score || 0) > 0">得{{ item.score }}龙珠</p>
                  </div>
                  <div class="count flex">
                    <p class="o"><span>¥</span>{{ Number((item.salePrice || 0) / 100).toFixed(2) }}</p>
                    <p class="t">起</p>
                    <p class="r">灵龙价</p>
                  </div>
                  <div class="last flex flex-between">
                    <p :style="{opacity: Number(item.returnAmount || 0) > 0 ? 1 : 0 }">推荐奖励 ¥{{ Number((item.returnAmount || 0) / 100).toFixed(2) }}起</p>
                    <p>销量 {{ item.saleNum }}</p>
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </template>
        <div v-else-if="finished" class="no-list">
          <img src="@/assets/img/user/myCollect/no.png" alt="" />
          <p>暂无收藏</p>
        </div>
      </van-list>
    </div>
    <div v-if="status && !baoStatusType" class="bottom-fix">
      <van-checkbox v-model="checked" @click="allFun">
        <template #icon="props">
          <img
            class="img-icon"
            :src="props.checked ? activeIcon : inactiveIcon"
          />
        </template>
        <div class="b-right flex align-center">
          <p class="all">全选</p>
          <p class="text">已选 <span>{{ totalNum }} </span>个宝贝</p>
          <p class="del" @click.stop="delFun">删除</p>
        </div>
      </van-checkbox>
    </div>
    <div v-if="showGou" class="gou-fix">
      <img src="@/assets/img/user/myCollect/gou.png" alt="" />
      <p>取消收藏操作成功</p>
    </div>
    <van-popup
      v-model:show="baoStatusType"
      position="top"
      :style="{ width: '100%' }"
      :close-on-click-overlay="true"
      class="bao-popup"
      @close="closeFun"
    >
      <div :class="['box-hold flex', { 'box-img': type == 3 }]">
        <template v-if="type == 1">   
          <p :class="{'yellow': statusIndex === 0 }" @click="statusFun(0, '正常状态')">正常状态</p>
          <p :class="{'yellow': statusIndex === 1 }" @click="statusFun(1, '失效')">失效</p>
        </template>
        <template v-if="type == 2">
          <p :class="{'yellow': timeIndex === 0 }" @click="timeFun(0, '7天内')">7天内</p>
          <p :class="{'yellow': timeIndex === 1 }" @click="timeFun(1, '30天内')">30天内</p>
          <p :class="{'yellow': timeIndex === 2 }" @click="timeFun(2, '30天外')">30天外</p>
        </template>
        <template v-if="type == 3">
          <div
            v-for="(tab, tIndex) in categoryList"
            :key="tIndex"
            :class="['li-img flex', {'li-mar': (tIndex+ 1) % 5 == 0}]"
            @click="cateFun(tab)"
          >
            <img :src="tab.category_icon" alt="" />
            <p class="img-text">{{ tab.category_name }}</p>
          </div>
        </template>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.my-collect {
  .no-list{
    .van-list__finished-text{
      opacity: 0;
    }
  }
  .van-list {
    height: 100%;
    overflow-y: scroll;
  }
  .m-header {
    height: 104px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    .tab {
      .van-tabs--line {
        width: 239px;
        margin: 0 auto;
      }
      .van-tabs__wrap {
        height: auto !important;
      }
      .van-tabs__nav {
        padding-top: 16px !important;
        padding-bottom: 15px !important;
        justify-content: space-between;
      }
      .van-tabs__line {
        width: 28px !important;
        height: 2px !important;
        background: #e62129 !important;
        bottom: 0 !important;
      }
      .van-tab {
        height: 21px !important;
        font-size: 15px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #999999 !important;
        line-height: 21px !important;
        padding: 0 !important;
        flex: unset !important;
        width: auto !important;
      }
      .van-tab--active {
        color: #e62129 !important;
      }
    }
  }
  .m-li {
    .van-checkbox {
      align-items: unset !important;
    }
    .van-checkbox__icon {
      display: none;
    }
    .van-checkbox__label {
      margin-left: 0 !important;
    }
  }
  .m-li-select {
    padding-left: 10px !important;
    .title{
      width: 180px !important;
    }
    .van-checkbox {
      .van-checkbox__icon {
        display: block !important;
        margin-right: 10px !important;
        height: 18px;
        width: 18px;
        margin-top: 37px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bottom-fix {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0px 0px;
    .van-checkbox {
      width: 345px;
      height: 56px;
      margin: 0 auto;
    }
    .van-checkbox__label {
      flex-grow: 1;
      margin-left: 0 !important;
      height: 56px;
      .b-right {
        height: 100% !important;
      }
    }
    img {
      width: 18px;
      height: 18px;
      margin: 0 10px 0 0;
    }
    .all,
    .text {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .text {
      flex-grow: 1;
      text-align: right;
      span {
        color: #e62129;
      }
    }
    .del {
      width: 80px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #e62129;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e62129;
      text-align: center;
      line-height: 40px;
      margin-left: 10px;
    }
  }
  .gou-fix {
    width: 160px;
    height: 98px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.75;
    text-align: center;
    padding-top: 22px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    img {
      width: 40px;
      height: 40px;
    }
    p {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      margin-top: 12px;
    }
  }
  .bao-popup {
    top: 52px;
    border-radius: 0px 0px 10px 10px;
    .box-hold {
      width: 345px;
      margin: 65px auto 0;
      p:not(.img-text) {
        width: 105px;
        height: 35px;
        line-height: 35px;
        background: #f0f3f3;
        border-radius: 18px;
        text-align: center;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-right: 15px;
        margin-bottom: 20px;
      }
      .yellow {
        background: #fdf4e5 !important;
        color: #f39801 !important;
      }
      .li-img {
        width: 40px;
        flex-direction: column;
        align-items: center;
        margin-right: 27px;
        img {
          width: 40px;
          height: 40px;
        }
        p {
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #231815;
          line-height: 16px;
          margin-top: 8px;
          white-space: nowrap;
          margin-bottom: 20px;
        }
      }
      .li-mar{
        margin-right: 0 !important;
      }
    }
    .box-img {
      width: 313px;
      padding-left: 5px;
      flex-wrap: wrap;
    }
  }
}
</style>