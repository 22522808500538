import {
    myCollectList
} from '@/utils/user.js';
import {
    getCategory
} from '@/utils/home.js';
import {
    cancelCollectGoods
} from '@/utils/goods.js';
export default {
    name: "MyCollect",
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            categoryIndex: 0,
            categoryId: "",
            categoryText: "",
            statusIndex: 0,
            statusText: "",
            timeIndex: 0,
            timeText: "",
            categoryList:[],
            baoStatusType: false,
            type: 0,
            checked: false,
            showGou: false,
            activeIcon: require('../../../assets/img/user/myCollect/sel.png'),
            inactiveIcon: require('../../../assets/img/user/myCollect/no-sel.png'),
            status: false,
            loading: false,
            finished: false,
            active: 0,
            list: [],
            goodsType: 1
        }
    },
    computed: {
        totalNum() {
            return this.list.reduce(
                (total, item) =>
                  total +
                  (item.checked ? 1 : 0),
                0
              );
        }
    },
    created() {
      this.getCategoryFunc()
    },
    methods: {
        toDetail(item) {
          this.$router.push({
              path: "goodsDetail",
              query: {
                goodsId: item.id
              }
          })
        },
        handleChange(val) {
          this.checked = false;
          this.showGou = false;
          this.status = false;
          this.goodsType = val == 1 ? 2 : 1
          this.type = 0
          this.statusIndex = 0;
          this.statusText = "";
          this.timeIndex = 0;
          this.timeText = "";
          this.categoryIndex = 0;
          this.categoryId = "";
          this.categoryText = "";
          this.baoStatusType = false;
          this.refreshData()
        },
        // 获取左边分类
        getCategoryFunc() {
            let data = {
                goodBigtype: 2,
                level: 2
            }
            getCategory(data).then(res => {
                this.categoryList = JSON.parse(JSON.stringify(res.data.category_list));
            })
        },
        statusFun(index, text) {
            this.statusIndex = index;
            this.baoStatusType = false
            this.statusText = text;
            this.refreshData()
        },
        timeFun(index, text) {
            this.timeIndex = index;
            this.baoStatusType = false
            this.timeText = text;
            this.refreshData()
        },
        cateFun(item) {
            this.categoryId = item.category_id
            this.categoryText = item.category_name
            this.baoStatusType = false
            this.refreshData()
        },
        baoFun(val) {
            this.baoStatusType = true
            if (this.type === val) {
                return
            }
            if (val == 1) {
                this.statusIndex = 0;
                this.statusText = "";
            } else if (val == 2) {
                this.timeIndex = 0;
                this.timeText = "";
            } else {
                this.categoryId = "";
                this.categoryText = "";
            }
            this.type = val
        },
        closeFun() {
            this.baoStatusType = false
            if ((this.type == 1 && !this.statusText && this.statusIndex === 0) || (this.type == 2 && !this.timeText && this.timeIndex === 0) || (this.type == 3 && !this.categoryText && this.categoryIndex === 0)) {
              this.type = 0
              this.refreshData()
            }
        },
        refreshData() {
            this.pageNo = 1
            this.loading =  true
            this.finished =  false
            this.list =  []
            setTimeout(() => {
                this.getFansList();
            }, 500);
        },
        handleFun() {
          this.status = this.status ? false : true
        },
        allFun() {
            this.list.forEach(ele => {
                ele.checked = this.checked
            })
        },
        delFun() {
            let arr = []
            this.list.forEach(item => {
                if (item.checked) {
                    arr.push(item.id)
                }
            })
            if (arr.length === 0) {
                return
            }
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodsIds: arr.join(',')
            }
            cancelCollectGoods(data).then(res => {
                if (res && res.code == "0") {
                    this.status = false
                    this.showGou = true
                    setTimeout(() => {
                        this.showGou = false
                    }, 2000)
                    this.refreshData()
                }
            })
        },
        partChange() {
            let bool = true
            this.list.forEach(ele => {
                if (!ele.checked) {
                    bool = false
                }
            })
            this.checked = bool
        },
        onLoad() {
            this.loading = true
            // 异步更新数据
            setTimeout(() => {
                this.getFansList();
            }, 500);
        },
        getFansList() {
            const data = {
                memberId: this.$cookies.get('memberId'),
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                goodsType: this.goodsType
            }
            if (this.type === 1 && this.statusText) {
                data.isLaunch = this.statusText == "正常状态" ? 1: 0
            }
            if (this.type === 2 && this.timeText) {
                if (this.timeText == "7天内") {
                    data.timeType = 0
                } else if (this.timeText == "30天内") {
                    data.timeType = 1
                } else {
                    data.timeType = 2
                }
            }
            if (this.type == 3 && this.categoryId) {
                data.categoryId = this.categoryId
            }
            this.status = false
            this.checked = false
            myCollectList(data).then(res => {
                this.loading = false;
                if (res && res.code == "0") {
                    const arr = res && res.data && res.data.dataList ? res.data.dataList :  []
                    this.list = this.list.concat(arr)
                    if (arr.length < this.pageSize) {
                        this.finished = true;
                    } else {
                        this.pageNo++;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
    }
}