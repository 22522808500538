import axios from "./request"
// 商品详情
export const getGoodsDetail = (data) => {
    return axios({
        url: "/api/weixin/mall/goodDetail",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 领取优惠券
export const receiveCoupon = (data) => {
    return axios({
        url: '/api/wallet/couponget',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 根据品牌获取商品
export const getBrandGoods = (data) => {
    return axios({
        url: '/api/weixin/mall/getBrandGoods',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 爆款推荐
export const recommendGoods = (data) => {
    return axios({
        url: '/api/weixin/mall/queryGoodList',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取品牌信息
export const brandDetail = (data) => {
    return axios({
        url: '/api/weixin/mall/getBrandInfo',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 分享
export const shareGoods = (data) => {
    return axios({
        url: '/api/weixin/mall/wxshare/wxsharegoodpicture',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 收藏
export const collectGoods = (data) => {
    return axios({
        url: '/api/member/collectGoods',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 取消收藏
export const cancelCollectGoods = (data) => {
    return axios({
        url: '/api/member/cancelCollectGoods',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 秒杀列表
export const activityList = (data) => {
    return axios({
        url: '/api/wx/activityInfo/getTodayActivityList',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 通知预约https://wx.linglong-goods.com/FxService/api/weixin/mall/appointmentNotice?memberId=&activityId=&goodId=&startTime=
export const appointmentNotice = (data) => {
    return axios({
        url: '/api/weixin/mall/appointmentNotice',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 活动详情http://api.linglong-goods.com:5000/seckill/api/wx/activityInfo/activityInfo?activityId=
export const activityDetail = (data) => {
    return axios({
        url: '/api/wx/activityInfo/activityInfo',
        method: 'get',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

/**
 * 达人 分享
 * api/weixin/mall/wxshare/wxShareServiceGoodsPicture?memberId=123454
 */
 export const wxShareServiceGoodsPicture = (data) => {
    return axios({
        url: '/api/weixin/mall/wxshare/wxShareServiceGoodsPicture',
        method: 'get',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}